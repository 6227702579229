import React,{useState,useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,dateTimeFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import Table, {Thead, Tbody, Tr, Th, Td} from "react-row-select-table"

import moment from 'moment';
import _, { forEach } from 'lodash';
import '../commons/commons.css'
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import  InstitutionProfile from '../commons/institution_profile'
import ReactToPrint from "react-to-print";
import commaNumber from 'comma-number';
import { TextArea } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')

const TransferRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
  const classes = useStyles();
  useEffect(()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
  },[]);
  let [accs,accsSet] = useState([]);
  let [selectedAcc,selectedAccSet] = useState(null);
  let [opening_balance,opening_balance_set] = useState(0);
  let [closing_balance,closing_balance_set] = useState(0);

  let [message,message_set] = useState('')

  let [persons,personsSet] = useState([])
  let [cats,catsSet] = useState([])
  let [areas,areasSet] = useState([])
  let [types,typesSet] = useState([{type:'All'},{type:'debitor'},{type:'creditor'},{type:'employee'}])

  let [selectedCat,selectedCatSet] = useState(null)
  let [selectedArea,selectedAreaSet] = useState(null)
  let [selectedType,selectedTypeSet] = useState({type:'All'})

  let [print,printSet] = useState(false);
  let reportRef = useRef(null);
  let [reportLoading,reportLoadingSet] = useState(false);
  let [loadingSave,loadingSaveSet] = useState(false);

  

  let [byDateTime,byDateTimeSet] = useState({
        fromDate: currentDateStartTime(),
        toDate:currentDateEndTime()
  })

  let accRef = useRef(null);
  let fromDateRef = useRef(null);
  let toDateRef = useRef(null);



  useEffect(()=>{ 
   
    getSearchResult()

  },[]);


  useEffect(()=>{
    getApis()
  },[selectedType])


  const getApis = async ()=>{
    personsSet([])
    let newArr = []

    if(selectedType != null && (selectedType.type == 'debitor' || selectedType.type == 'creditor' || selectedType.type ==  'All') ){
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{multiType: selectedType.type ==  'All' ? ['debitor','creditor'] : [selectedType.type] },{headers:{'auth-token':authInfo.token}}).then(res=>{
            let newPer1 =   res.data.map((person)=>{
                let per = {
                    id: person.acc_id,
                    name: person.acc_name,
                    institution: person.institution_name,
                    address: person.address,
                    mobile_no: person.contact_no, 
                }
                return per;
              });
              newArr =  newArr.concat(newPer1);
            })
    }

    if(selectedType != null && (selectedType.type ==  'employee' || selectedType.type ==  'All') ){
        await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            let newPer1 =   res.data.map((person)=>{
                let per = {
                    id: person.employee_id,
                    name: person.employee_name,
                    institution: '',
                    address: '',
                    mobile_no: person.contact_no,
                }
                return per;
              });
              newArr =  newArr.concat(newPer1);
            })
    }
        
      
    



        





    personsSet(newArr)



 
   }


   
//   let getAreas = async ()=>{
//     await axios.post(`${API_URL}/api/get-areas`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
//       areasSet(res.data.message)
//     })
//   }



//   let getAccs = async ()=>{
//     await axios.post(`${API_URL}/api/get-accs`,{catId : selectedCat != null ? selectedCat.acc_cat_id : null,
//           areaId : selectedArea != null ? selectedArea.area_id : null},{headers:{'auth-token':authInfo.token}}).then(res=>{
//           accsSet(res.data)
//     })
//   }

let [senderPersons,senderPersonsSet] = useState([])
let [st,st_set] = useState('')


let newArr = []
 let a = (v)=>{




  
   let ar =  v.map((ind)=>{
      return  persons[ind]
    })


  newArr = ar
  
   

 }


let action = async ()=>{

    if(message.trim() == ''){
        swal({
            title: `Message is Required.`,
            icon:'warning'
          })
           
          return false;
    }

    let numbers = []
      persons.map(async (person)=>{
        if(person.mobile_no.length == 11){
            numbers.push(person.mobile_no)
        }
      })


      loadingSaveSet(true) 




      await axios.post(`${API_URL}/api/sent-sms`,{  
        persons: newArr,
        msg :  message
      },{headers:{'auth-token': authInfo.token}}).then(res=>{
        loadingSaveSet(false);
       
       
      swal({
        title: `Message Sent Success`,
        icon:'success'
      })

      message_set('')
       
      });



//       newArr.forEach(async(num)=>{

        
//     if(message.trim() != '' && num.mobile_no.replace('-','').length == 11){
//         console.log(num.mobile_no)
//       await axios.post(`https://sms.solutionsclan.com/api/sms/send`,
//       {
//           "apiKey" : "A0004343e3aabdb-c9c9-4009-ba02-62d5123fdf81",
//           "contactNumbers" : num.mobile_no.replace('-',''),
//           "senderId" : "SK TELECOM",
//           "textBody" : message
//       },
  
//       {headers:{
//         contentType: 'application/json; charset=utf-8',
//         'auth-token':authInfo.token
//       }}
    
//     ).then(res=>{
//       console.log(res.data)
//     })
  
//     }

// })





      
    
}
 

  let getSearchResult = async ()=>{



  //     let payload = {
  //         accId: selectedAcc != null? selectedAcc.acc_id:null,
  //         fromDate: byDateTime.fromDate,
  //         toDate: byDateTime.toDate
  //     }

  //     reportLoadingSet(true)
  //     await axios.post(`${API_URL}/api/get-acc-summary-report`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
  //       printSet(true)
  //       reportLoadingSet(false)
  //       personsSet(res.data)
       
  // })

  }



  let ReportDom = React.forwardRef((props,ref)=>{
      return (
        <div ref={ref}>
                  <InstitutionProfile />
                  <div style={{clear:'both'}}></div>
<Paper style={{width:'100%',paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold'}}> Message Sender </p>

<p style={{float:'left',width:'30%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
{selectedAcc != null ?`acc Account : ${selectedAcc!=null?selectedAcc.acc_name:''}`:''}
</p>

<p style={{float:'right',width:'40%',textAlign:'right',margin:0,padding:0}}> Statement From { moment(byDateTime.fromDate).format(dateTimeFormat)  } <br/> TO { moment(byDateTime.toDate).format(dateTimeFormat) }
</p>
</Paper> 



              {
     persons.length>0?(
      <Paper className={classes.paper} style={{marginTop:'8px',marginBottom:'5px'}} >
          
          <h2>Persons Type : {selectedType != null ? selectedType.type:''}</h2>


       




                    <Table onCheck={value => {
                      a(value)
                      return false
                      }} defaultCheckeds={[1,3]}>
                <Thead>
                <Tr>
                    <Th>SL</Th>
                    <Th>Name</Th>
                    <Th>Institution</Th>
                    <Th>Address</Th>
                    <Th>Mobile No</Th>
                </Tr>
                </Thead>
                <Tbody>
                  {
                    persons.map((person,ind)=>(
                      <Tr>
                        <Td>{parseFloat(ind)+1}</Td>
                        <Td>{person.name}</Td>
                        <Td>{person.institution}</Td>
                        <Td>{person.address}</Td>
                        <Td>{person.mobile_no}</Td>
                       </Tr>
                    ))
                  }
                
              
                </Tbody>
            </Table>
          </Paper>
     ):''
  }

        </div>
      )
  })

    return(
        <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
  marginBottom: '2px'}}> Message Sender</h4> 
  
  

<Grid container spacing={3} > 



     
        <Grid item xs={12}   sm={2}> 
        <Autocomplete 
        size="small"

        autoHighlight
            openOnFocus={true}
            style={{width:'100%',height:'20px'}}
            options={types}
            value={selectedType}
            getOptionLabel={(option) => option.type}
            onChange={(event,selectedObj)=>{
               selectedTypeSet(selectedObj)
            }}

       

            renderInput={(params) => <TextField 
               
              {...params} 
              label="Person Type" 
              variant="outlined"
              />} 
        />
        </Grid>
        
        
        {/* <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getSearchResult}
                          disabled={reportLoading?true:false}
                      >
                     Search 
                    </Button>
      </Grid> */}

      </Grid> 
      </Paper> 
 



            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>



              <Grid  container  xs={12}   sm={12} style={{marginTop:'15px',marginLeft:'10px',marginBottom:'5px'}}>

<Grid item xs={12}   sm={4}>
<TextareaAutosize onChange={(e)=>{message_set(e.target.value)}} value={message}  name="message" 
  style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="message..." 
  rowsMin={6} placeholder="message..." />
</Grid>

<Grid item xs={12}   sm={2}>
<button disabled={loadingSave ? true: false} onClick={()=>action()} style={{background: '#167804',
    color: '#fff',
    fontSize: '20px',
    marginTop: '27px',cursor:'pointer'}}>Send Message</button>
</Grid>
</Grid>



              </Paper>
           
            </Grid>
        </Grid>
       


        

            <ReportDom ref={reportRef} />

</div>

    )
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(TransferRecord);
