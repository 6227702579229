import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import {API_URL} from '../../../config.json';
import InvoiceBody from './sales_order_voucher_body'
import {currentDateTime,convertNumberToWords, dateTimeFormat,dateFormat} from '../../../lib/functions'
import PrintIcon from '@material-ui/icons/Print';
import moment from 'moment';
import ReactToPrint from "react-to-print";
import Font, {Text} from 'react-font'

import '../../commons/voucher.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

class PrintAbleSection extends React.Component {
      
  constructor(props) {
    super(props); 


  }

  state = {
    salesOrder:[],
    institution:null,
    accDue:0,
  }

  componentDidMount() {
    this.getSalesOrder()
    this.setState({institution:this.props.institution});
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sale_o_id != this.props.sale_o_id) {
        this.getSalesOrder()
    }

  }
   
  getSalesOrder = async()=>{
      let accId = null
      await axios.post(`${API_URL}/api/get-sales-order-record-with-details`,{sale_o_id:this.props.sale_o_id,from:'voucher'},{headers:{'auth-token':this.props.authInfo.token}}).then(res=>{
       if(res.data.length == 0) return false

        // Detail Data to Product Cart - Start
      let itemCartData =  res.data[0].details.map((item)=>{ 
        let serials = []
        
        serials = item.serials.split(',');
        if(item.is_serial == 'yes' ){
          serials = serials.map((slNo)=>{
            return {serial_number : slNo}
          })
        }else{
          serials = []
        }

          let updateItem = {
            warehouse_id : item.warehouse_id,
            warehouse_name : item.warehouse_name,
            item_id : item.item_id,
            item_name : item.item_name,
            display_text : item.display_text,
            is_serial: item.is_serial,
            item_qty:item.item_qty,
            item_rate:item.item_rate,
            item_discount:item.item_discount,
            item_discount_per:item.item_discount_per,
            discount_acc_id:item.discount_acc_id,
            discount_acc_name:item.discount_acc_name,
            item_tax:item.item_tax,
            item_tax_per:item.item_tax_per,
            tax_acc_id:item.tax_acc_id,
            tax_acc_name:item.tax_acc_name,
            serials,
            item_total:item.item_total,
            retail_qty:item.retail_qty,
            sale_qty:item.sale_qty,
            sale_rate:item.sale_rate,
            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
            done_qty_display: item.done_item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.done_retail_qty +' ' +item.base_unit_name:''),
            unit_symbol: item.unit_symbol,
            base_unit_name: item.base_unit_name,
            unit_name: item.unit_name,
            conversion: item.conversion,
          }
          return updateItem;
        });

        res.data[0].details = itemCartData

        this.setState({salesOrder:res.data[0]});
        
      });

      // await axios.post(`${API_URL}/api/get-acc-due`,{accId:accId},{headers:{'auth-token':this.props.authInfo.token}}).then( (res)=>{
      //   if(res.data.length ==0){
      //     this.setState({accDue:0})
      //     return false
      //   } 
      //   this.setState({accDue:res.data[0].dueAmount})
      //  })

      
  }



  render(){
    let {salesOrder,institution,accDue}  = this.state;
    return(
      <div style={{padding:'20px',paddingBottom:'0px'}} >
        {
          salesOrder.length!=0?(
              <>
                <Grid container>
                            <Grid item xs={12} sm={12}>
                                 <h3 className={"invoice-title"}> ORDER VOUCHER</h3>
                            </Grid>
                      </Grid>
                      <Grid container style={{marginBottom: '12px',fontSize:'14px',color:"#222"}}>
                           <Grid item xs={6} sm={6}> 
                                 <strong>Customer/ Debtor Code : </strong> <span>{ salesOrder.acc_code }</span><br/>
                                 <strong> Name : </strong> <span>{ salesOrder.acc_name }</span><br/>
                                 <strong>Institution  : </strong> <span>{ salesOrder.institution_name }</span><br/>
                                 <strong> Address : </strong> <span>{ salesOrder.address }</span><br/>
                                 <strong> Contact No : </strong> <span>{ salesOrder.contact_no }</span><br/>
                            </Grid>
                            <Grid item xs={6} sm={6} style={{textAlign:'right'}}>
                                <strong>Voucher No : </strong> <span>{ salesOrder.sale_order_no }</span><br/>  
                                 <strong> Order Date  : </strong> <span>{ moment(salesOrder.created_date).format(dateFormat)  }</span><br/>
                                 <strong> Order By  : </strong> <span>{ salesOrder.user_full_name }</span><br/>

                            </Grid>
                      </Grid>
                     
                      <InvoiceBody salesOrderData={salesOrder} institution={institution} authInfo={this.props.authInfo}/>

                      <Grid container>
                            <Grid xs={6} sm={6} style={{marginTop: '25px'}}>
                                  <table>
                                        
                                  </table>
                            </Grid>
                            <Grid xs={6} sm={6} style={{marginTop: '2px'}}>
                                      <table style={{width:'100%'}}>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Sub Total : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(salesOrder.sub_total).toFixed(2))} </td>
                                        </tr>
                                     {
                                       salesOrder.is_cal_type == 'on_total'?(<>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Vat Amount:</td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(salesOrder.total_tax).toFixed(2))} </td>
                                        </tr>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Discount Amount:</td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(salesOrder.total_discount).toFixed(2))} </td>
                                        </tr>
                                       </>):''
                                     }
                                       
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Transport Cost : </td>
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}> {format(parseFloat(salesOrder.total_transport_cost).toFixed(2))} </td>
                                        </tr>
                                       
                                        <tr >
                                        <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
                                          <td style={{width:'100%',borderTop: '1px solid #222'}}></td>
                                        </tr>
                                        <tr>
                                          <td style={{color: '#222',fontWeight:'bold'}}>Grand Total : {this.props.authInfo.currency}</td> 
                                          <td style={{textAlign:"right",color: '#222',fontWeight:'bold'}}>  { format(parseFloat(salesOrder.total_amount).toFixed(2))} </td>
                                        </tr>
                                      
                                  </table>
                            </Grid>

                            <Grid xs={12} sm={12}>
                                  <p style={{color: '#222'}}>In Word of Grand Total : {convertNumberToWords(salesOrder.total_amount)} </p>
                                  <p style={{color: '#222'}}>Narration : <p style={{whiteSpace: 'pre-line',margin: '0',marginLeft: '10px',padding: '0'}}>{salesOrder.narration}</p> </p>
                            </Grid>

                      </Grid>
              </>

          ):''
        }
         
      </div>
    )
  }
}


class ComponentToPrint extends React.Component {

   
  constructor(props) {
    super(props);  
     }
  state = {
    authInfo:this.props.authInfo,
    sale_o_id:this.props.sale_o_id,
    institution:this.props.institution,
  }


  render() {
      let institution =   this.props.institution;
    let a4css = `
    .a4 {
      font-size: 16px;
      
  }
  .a4 body, table{
      font-size: 14px;
  }
    `

    let hafa4css = `
    .hafa4 { 
      width:500px !important;
  }
  .hafa4 body, table{
      font-size: 14px;
  }
    `

    let poscss = `
    .pos {
      width: 302px !important;
    }
    .pos body{
      font-size: 14px;
    }
  .pos body, table{
      font-size: 14px;
  }
    `


   
    return (
      <div className='print-source' >
           {/* Print  DOCUMENT */}

                 {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'a4'?(
                      <html lang="en">
                    <head>
                       <meta charset="UTF-8" />
                        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                        <title>PURCHASE ORDER VOUCHER</title>
                        <style >
                           {a4css}
                        </style>
                    </head>
                    <tbody className="a4" style={{padding:'5px'}}>



                     

                     <table style={{width:'100%'}}>

                       <thead>
                          <tr>
                            <td><div style={{height:'10px'}}></div></td>
                            </tr>
                       </thead>

                       <tbody>
                         <tr>
                           <td>
                            
                        <div className="invoice-head" style={{width:'100%'}}>
                            <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                  <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                            </div>
                            <div style={{width:'5%'}}></div>
                            <div className="invoice-desc" style={{width:'75%',float:'left',marginLeft:'5px'}}>
                                  <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                  <Font family='Roboto'  sans-serif>
                                  <p style={{textAlign:'center',color:'#222',fontSize:'16px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>

                                  </Font>
                            </div>
                        </div>
                           </td>
                           </tr>
                         <tr>
                           <td>
                           <PrintAbleSection   authInfo={this.state.authInfo} sale_o_id={this.props.sale_o_id} institution={institution} />

                           </td>
                           
                         </tr>


                         <tr>
                           <td>
                           <div className="invoice-footer-bottom"  >
                      <div className="invoice-footer" style={{marginTop:'60px'}}>
                                <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>


                      <div className="invoice-footer" >
                       
                            <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                Print Date  :  {moment().format(dateFormat)} 
                           </p>
                           <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                Developed By : Soft Task - Contact No = 01749-508007
                           </p>
                      </div>
                      
                      </div>
                           </td>
                         </tr>
                       </tbody>

                       <tfoot >
                         <tr>
                         <td>
                           <div style={{height:'100px'}}></div>
                           </td>
                         </tr>
                       </tfoot>
                     </table>

                      
                    </tbody>
                    </html>
                     ):''
                 }
                    


                  {/* {  1/2 - A4   Print */
                    institution != null &&  institution.pro_print_type == '1/2a4'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>PURCHASE ORDER VOUCHER</title>
                          <style >
                             {hafa4css}
                          </style>
                      </head>
                      <tbody  className="hafa4" style={{padding:'5px'}}>

                       <table style={{width:'100%'}}>
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>
                         </thead>
  
                         <tbody>
                           <tr>
                             <td>
                              
                          <div className="invoice-head" style={{width:'100%'}}>
                              <div className="invoice-logo" style={{width:'20%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                    <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                              </div>
                              <div style={{width:'5%'}}></div>
                              <div className="invoice-desc" style={{width:'75%',float:'left',marginLeft:'5px'}}>
                                    <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                    <Font family='Roboto'  sans-serif>
                                    <p style={{textAlign:'center',color:'#222',fontSize:'15px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>
  
                                    </Font>
                              </div>
                          </div>
                             </td>
                             </tr>
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} sale_o_id={this.props.sale_o_id} institution={institution} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom "  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                                  <p style={{float:'left',marginLeft:'20px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold'}}>
                                  Print Date & Time :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'right',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px'}}>
                                  Developed By : Soft Task - Contact No = 01749-508007
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }


                  {/* {   A4 Print */
                    institution != null &&  institution.pro_print_type == 'pos'?(
                      <html lang="en">
                      <head>
                         <meta charset="UTF-8" />
                          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                          <title>PURCHASE ORDER VOUCHER</title>
                          <style >
                             {poscss}
                          </style>
                      </head>
                      <tbody  className="pos" style={{padding:'5px'}}>

                       <table  className="pos">
  
                         <thead>
                            <tr>
                              <td><div style={{height:'10px'}}></div></td>
                              </tr>
                         </thead>
  
                         <tbody>
                           <tr>
                             <td>
                              
                          <div className="invoice-head pos">
                              <div className="invoice-logo" style={{width:'35%',float:'left',marginLeft:'0%',marginTop:'20px',overflow:'hidden'}}>
                                    <image alt="logo" src={`${API_URL}/${institution.pro_logo}`} style={{width:'100px',height:'100px'}} />
                              </div>
                              <div style={{width:'5%'}}></div>
                              <div className="invoice-desc" style={{width:'60%',float:'right',marginLeft:'5px',textAlign:'center'}}>
                                    <h2 style={{textAlign:'center'}}>{institution.pro_name}</h2>
                                    <Font family='Roboto'  sans-serif>
                                    <p style={{textAlign:'center',color:'#222',fontSize:'12px',margin:'0px',padding:'0px'}}>{institution.pro_desc}</p>
  
                                    </Font>
                              </div>
                          </div>
                             </td>
                             </tr>
                           <tr>
                             <td>
                             <PrintAbleSection   authInfo={this.state.authInfo} sale_o_id={this.props.sale_o_id} institution={institution} />
  
                             </td>
                             
                           </tr>
  
  
                           <tr>
                             <td>
                             <div className="invoice-footer-bottom pos"  >
                        <div className="invoice-footer" style={{marginTop:'60px'}}>
                             
                                   <p style={{float:'right',marginRight:'25px',borderTop:'1px solid #222',width:'150px',textAlign:'right'}}>Authorized By</p>
                        </div>
  
  
                        <div className="invoice-footer " >
                         
                              <p style={{float:'left',fontSize:'12px',marginLeft:'20px',color:'#222',fontWeight:'bold',margin:'0px'}}>
                                  Print Date  :  {moment().format(dateFormat)} 
                             </p>
                             <p style={{float:'left',marginRight:'12px',marginRight:'25px',fontSize:'10px',color:'#222',fontWeight:'bold',padding:'0px',margin:'0px'}}>
                                  Developed By : Soft Task - Contact No = 01749-508007
                             </p>
                        </div>
                        
                        </div>
                             </td>
                           </tr>
                         </tbody>
  
                         <tfoot >
                           <tr>
                           <td>
                             <div style={{height:'100px'}}></div>
                             </td>
                           </tr>
                         </tfoot>
                       </table>
  
                        
                      </tbody>
                      </html>
                     ):''
                 }
                    
           
      </div>
    );
  }
}

let PurchaseOrderVoucher = ({authInfo,sale_o_id,institution})=>{
  let componentRef = useRef()
  return (
    <>
            <Grid container>
                  <Grid item xs={12} sm={11} className={"invoice-section"}>
                    {/* Main Grid Start */}
                    <div>
                    <ReactToPrint
                      trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '18px'}} />}
                      content={() => componentRef}
                      onBeforePrint = {() => componentRef}
                    />

    <ComponentToPrint ref={el => (componentRef = el)} authInfo={authInfo} sale_o_id={sale_o_id} institution={institution} />


                    <PrintAbleSection  authInfo={authInfo} sale_o_id={sale_o_id} institution={institution} />

                  </div>
                  </Grid>
            </Grid>
    </>
  )
}



  const mapStateToPops = (state)=>{
    return {
      authInfo:state.authInfoReducer
    }
}
export default connect(mapStateToPops,{})(PurchaseOrderVoucher);
