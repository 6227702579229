import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AsyncSelect from 'react-select/async';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';



import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getJournals();
    currentRouteSet(pathSpliter(location.pathname,1));
    getJrnCode();
  },[])

    // Init states start
 
    
    let [jrn_id,jrn_id_set] = useState(0)
    let [journals,journalsSet] = useState([])
    let [jrnCart,jrnCartSet] = useState([])
    let [loadAccounts,loadAccountsSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [jrn_code,jrn_code_set] = useState('');
    let [jrn_ind_id,jrn_ind_id_set] = useState(null);

    let [debit_amount,debit_amount_set] = useState('');
    let [credit_amount,credit_amount_set] = useState('');
    let [narration,narration_set] = useState('');
    
    
    const [jrnDate, jrnDateSet] = useState(currentDateTime);
    const [selectedAccount, selectedAccountSet] = useState(null);

    let acc_ref = useRef(null)
    let credit_amount_ref = useRef(null)
    let debit_amount_ref = useRef(null)


  
  useEffect(()=>{
    getJournals()
  },[jrnDate])

   
    const jrnDateChange = (date) => {
        jrnDateSet(date);
      };


    
    const getJournals = async ()=>{
        await axios.post(`${API_URL}/api/get-journal-with-details`,{oneDate:jrnDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
        journalsSet(res.data)
      })
    }


    
    const [account_name, account_name_set] = useState("");

    useEffect(()=>{
      getLoadAccounts()
    },[account_name])

    const getLoadAccounts = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name,search:true,
         multiType:['capital','cash_in_hand','bank_account',
                    'indirect_expense','loan','investment','fixed_asset',
                    'creditor','debitor']
        },{headers:{'auth-token':authInfo.token}}).then(res=>{
          loadAccountsSet(res.data)
        })
    };

    

    const getJrnCode = async() => {
      return  await axios.post(`${API_URL}/api/get-journal-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          jrn_code_set(res.data)
        })
    };
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{


      let days = moment().diff(jrnDate, "days")


      if(days >= 3 && authInfo.role != 'super_admin'){
        swal({
          title:`Now can't be Entry !`,
          icon:'warning',
       })
       return false
      }




            let totalDebit = _.sumBy(jrnCart,(tran)=>parseFloat(tran.debit_amount))
            let totalCredit = _.sumBy(jrnCart,(tran)=>parseFloat(tran.credit_amount))

            let count = 0;
            for (var i = 0; i < jrnCart.length; i++) {
              let counting =   jrnCart.filter(jrn=>{
                return jrn.acc_id == jrnCart[i].acc_id
              });
              if(counting.length >= 2 ){
                count = counting.length
                break;
              }
            }


            if(count >= 2 ){
              swal({
                title:`You can't select same account multitple times.`,
                icon:'warning'
              });

              return false
            }

           


            
           

            if(totalDebit  != totalCredit){
              swal({
                title:`Debit Amount & Credit Amount Total Should be Same.`,
                icon:'warning'
              });
              return false
            }else if(totalDebit == 0 &&  totalCredit == 0){
              swal({
                title:`Debit Amount & Credit Amount Total cannot be 0.`,
                icon:'warning'
              });
              return false
            }else if(jrnCart.length == 0){
              swal({
                title:`Journal Cart is Empty.`,
                icon:'warning'
              });
              return false
            }else{
            let payLoad = {
              jrn:{
                jrn_code:jrn_code.trim(),
                creation_date:jrnDate,
                debit_total:totalDebit,
                credit_total:totalCredit,
                narration,
                jrn_id,
                action
              },
              jrnDetail:jrnCart
            }
            //loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-journal`,{  
              ...payLoad
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
            //  loadingSaveSet(false);
             if(res.data.error == false){
               swal({
                 title: res.data.msg,
                 icon:'success'
               })
               jrnDateSet(currentDateTime)
               jrnCartSet([])
               action_set('create')
               narration_set('')
               jrn_id_set(0)
               getJrnCode()
               getJournals()
             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }
         
            
          
          
    }


  const removeJournalTran = (sl)=>{
    let preCopy =  [...jrnCart];
    preCopy.splice(sl, 1);
    jrnCartSet(preCopy)

  }

  const addTOJournal = ()=>{
    

    if(selectedAccount.acc_id == 0){
      swal({
        title:`Select a Account `,
        icon:'warning'
      });
      return false
    }else if(debit_amount > 0 && credit_amount > 0){
      swal({
        title:`Amount Should be Debit or Credit `,
        icon:'warning'
      });
      return false
    }else if((debit_amount == 0 || debit_amount == '') &&  (credit_amount == 0 || credit_amount == '')){
      swal({
        title:`Debit Amount & Credit Amount  cannot be 0.`,
        icon:'warning'
      });
      return false
    }else{

      let tran = {
        acc_id : selectedAccount.acc_id,
        acc_name : selectedAccount.acc_name,
        debit_amount : debit_amount =='' ? 0 :debit_amount,
        credit_amount : credit_amount =='' ? 0 :credit_amount
      }
      if(jrn_ind_id == null){
        jrnCartSet([...jrnCart,tran])
      }else{
        let preCopy =  [...jrnCart];
        preCopy[jrn_ind_id] = tran
        jrnCartSet(preCopy)

      }


      jrn_ind_id_set(null)
      selectedAccountSet(null)
      debit_amount_set('')
      credit_amount_set('')
      
     }

    
     

   
    }

    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>jrnEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>jrnDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const jrnEdit = (row,index)=>{
      let journal =  journals.filter((jrn)=>jrn.jrn_id == row[0])
      journal = journal[0]

      let days = moment().diff(journal.creation_date, "days")
    
    
      if(days >= 3 && authInfo.role != 'super_admin'){
        swal({
          title:`Now can't be delete !`,
          icon:'warning',
       })
       return false
      }


      jrnCartSet(journal.details)
      
      jrn_id_set(journal.jrn_id)
      action_set('update')
      jrn_code_set(journal.jrn_code)
      jrnDateSet(journal.creation_date)
      narration_set(journal.narration)
     
    }


    const editJournalTran = (row,sl)=>{
      jrn_ind_id_set(sl)
      selectedAccountSet({acc_name:row.acc_name,acc_id:row.acc_id})
      debit_amount_set(row.debit_amount)
      credit_amount_set(row.credit_amount)
    }


    const jrnDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let journal =  journals.filter((jrn)=>jrn.jrn_id == row[0])

          let days = moment().diff(journal.creation_date, "days")
    
    
          if(days >= 3 && authInfo.role != 'super_admin'){
            swal({
              title:`Now can't be delete !`,
              icon:'warning',
           })
           return false
          }

          
          await axios.post(`${API_URL}/api/delete-journal`,{jrn_id:journal[0].jrn_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getJournals();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "jrn_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "jrn_code",label: "Journal Code",options: {filter: true,sort: true}},
      {name: "creation_date",label: "Journal Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[3]).format(dateFormat) }</p>) 
        }
      }},
      {name: "debit_total",label: "Debit Total",options: {filter: true,sort: true}},
      {name: "credit_total",label: "Credit Total",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_name",label: "Created By",options: {filter: true,sort: true}},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Journal Entry </h2>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
    

      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccount}
              onChange={(e,selectedObj)=>{
                selectedAccountSet(selectedObj)
                if (e.key === "Enter") {
                  debit_amount_ref.current.focus()
                 }
              }}
              renderInput={(params) => (
                <TextField
                  onChange={(e)=>{
                    account_name_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Choose Account (By Search)"
                  variant="outlined"
                
                />
              )}
          />


<br/>
        <Grid item xs={12} sm={12}> 
        <TextField type="number"  
         inputRef={debit_amount_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            credit_amount_ref.current.focus()
           }
         }} autoComplete='off' className={classes.fullWidth} 
        label="Debit Amount" name="debit_amount" value={debit_amount} 
        variant="outlined" size="small" onChange={(e)=>{
          
        
                if(credit_amount>0){
                    credit_amount_set(0)
                 }
                    debit_amount_set(e.target.value)
                
           
            }} />
        </Grid>
        <br/>

        <Grid item xs={12} sm={12}> 
        <TextField  type="number"  
            inputRef={credit_amount_ref}
            onKeyDown={event => {
              if (event.key === "Enter") {
                addTOJournal()
              }
            }} 
            autoComplete='off' className={classes.fullWidth} 
        label="Credit Amount" name="credit_amount" value={credit_amount} 
        variant="outlined" size="small" onChange={(e)=>{
            if(debit_amount>0){
                debit_amount_set(0)
           }
                credit_amount_set(e.target.value)
            

            }} />
        </Grid>
        <span>Press Enter Key to Journal </span>


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOJournal()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Journal</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={8}>

        <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={4} style={{float:'left'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        label="Journal Code" name="jrn_code" value={jrn_code} 
        variant="outlined" size="small" onChange={(e)=>jrn_code_set(e.target.value)} />
        </Grid>

        <Grid item xs={1} sm={2}>
          <div></div>
        </Grid>


        <Grid item xs={12} sm={4} style={{float:'right',marginTop: '-25px',marginLeft:'5px'}}> 

        <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '25px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
            label="Journal Date" 
            inputFormat={dateTimeFormat}
           
            value={jrnDate}
            onChange={(e)=>jrnDateChange(e)}
            renderInput={(params) => <TextField 
              onKeyDown={event => {
                if (event.key === "Enter") {
                  saveFormAction()
                }
            }}
            
              {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>


        </Grid>
        </Grid>
     
     




        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>Account</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Debit Amount</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Credit Amount</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                jrnCart.map((acc,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == jrn_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{acc.acc_name}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.debit_amount).toFixed(2))}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.credit_amount).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editJournalTran(acc,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{removeJournalTran(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                jrnCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={2} style={{fontWeight:'bold'}}>Total Debit & Credit Amount : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(jrnCart,(tran)=>parseFloat(tran.debit_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(jrnCart,(tran)=>parseFloat(tran.credit_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right">----------</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer  style={{float:'right'}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>


        </Grid>



        </Grid>


      


     



        

      
  
      </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Journal Entry List"}
      data={journals}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);