import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {acc_types} from '../../account_types.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'


import DateFnsUtils from '@date-io/date-fns';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import moment from 'moment';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {pathSpliter,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,dateFormat} from '../../lib/functions'


import {currentRouteSet} from '../../actions/actions';


const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getAccounts();
    getLocations()
    currentRouteSet(pathSpliter(location.pathname,1));
    getReminders()
    
  },[])

    // Init states start
 
    
    let [accounts,accountsSet] = useState([])
    let [locations,locationsSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)
    let [selectedAccount,selectedAccountSet] = useState(null)
    let [reminders,remindersSet] = useState([])
    

    let [action,action_set] = useState('create');
    let [acc_id,acc_id_set] = useState(0);
    let [acc_name,acc_name_set] = useState('');
    let [customer_name,customer_name_set] = useState('');
    let [address,address_set] = useState('');
    let [contact_no,contact_no_set] = useState('');
    let [due_balance,due_balance_set] = useState(0);
    let [credit_limit,credit_limit_set] = useState(0);
    let [email_address,email_address_set] = useState('');
    let [bank_acc_number,bank_acc_number_set] = useState('');
    let [bank_acc_name,bank_acc_name_set] = useState('');
    let [bank_branch,bank_branch_set] = useState('');
    let [bank_acc_type,bank_acc_type_set] = useState('');
    let [party_type,party_type_set] = useState('no');

    let [selectedLocation,selectedLocationSet] = useState(null);
    
    
    const [selectedDate, selectedDateSet] = useState(currentDateTime);

    let [retailer,retailerSet] = useState(false)
    let [wholesaler,wholesalerSet] = useState(false)
    let [distributor,distributorSet] = useState(false)

    const acc_name_ref = useRef(null)
    const acc_type_ref = useRef(null)
    const acc_due_balance_ref = useRef(null)
    const acc_pay_date_ref = useRef(null)

    const institution_ref = useRef(null)
    const address_ref = useRef(null)
    const contact_ref = useRef(null)
    const location_ref = useRef(null)
    const email_ref = useRef(null)
    const credit_limit_ref = useRef(null)

    const bank_acc_name_ref = useRef(null)
    const bank_acc_number_ref = useRef(null)
    const bank_acc_branch_ref = useRef(null)
    const bank_acc_type_ref = useRef(null)
    


    const handleDateChange = (date) => {
        selectedDateSet(date);
      };

    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{
         
            loadingSaveSet(true);

            await axios.post(`${API_URL}/api/save-customer-reminder`,{
              due_balance:due_balance =='' ? 0 : due_balance,
              customer_name,
              address,
              contact_no,
              pay_date:selectedDate,
              action,
              acc_id
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
              getReminders()
              if(res.data.error){
                swal({
                  title: res.data.msg,
                  icon:'warning'
                })
              }else{
                swal({
                  title: res.data.msg,
                  icon:'success'
                })

                acc_id_set(0)
                acc_name_set('')
                action_set('create')
                selectedAccountSet(null)
                due_balance_set(0)
                customer_name_set('')
                address_set('')
                contact_no_set('')
                party_type_set('no')
                selectedDateSet(currentDateTime)
                selectedLocationSet(null)
                credit_limit_set(0)
                email_address_set('')
                bank_acc_number_set('')
                bank_acc_name_set('')
                bank_branch_set('')
                bank_acc_type_set('')

                retailerSet(false)
                wholesalerSet(false)
                distributorSet(false)



              }
              
            });
          
          
    }

    let [defaults,defaultsSet] = useState(['Discount on purchase','discount on service','Vat & Tax Account','Discount on sale',
                                'Sales','Sales Return','Discount on service','Purchase','Purchase Return','Salary','Services','Service Expense'])


  

  
    const getAccounts = async ()=>{
      await axios.post(`${API_URL}/api/get-accounts-by-search`,{type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        accountsSet(res.data)
      })
    }


    const getReminders = async ()=>{
        await axios.post(`${API_URL}/api/get-customer-reminders`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          remindersSet(res.data)
        })
      }

    const getLocations = async ()=>{
      await axios.post(`${API_URL}/api/get-locations`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        locationsSet(res.data)
      })
    }

    const getDueBalance = async ()=>{
        await axios.post(`${API_URL}/api/get-sundry-debitor-balance`,{customerId: selectedAccount != null ? selectedAccount.acc_id:0},{headers:{'auth-token':authInfo.token}}).then(res=>{
          due_balance_set(res.data.length != 0 ?  res.data.total_balance : 0)
        })
      }
    



    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>accountEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>accountDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const accountEdit = (row,index)=>{
      let account =  reminders.filter((account)=>account.acc_id == row[0])

      
      
      acc_id_set(account[0].acc_id)
      acc_name_set(account[0].acc_name)
      action_set('update')


      due_balance_set(account[0].due_balance)
      customer_name_set(account[0].customer_name)
      address_set(account[0].address)
      contact_no_set(account[0].contact_no)
      selectedDateSet(account[0].pay_date)
     

    


    }


    const accountDelete = async (row)=>{

      let account =  reminders.filter((account)=>account.acc_id == row[0])


      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{

        

 
        if(yes){
          await axios.post(`${API_URL}/api/delete-reminder-account`,{acc_id:account[0].acc_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getAccounts();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })

                  getReminders()
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "acc_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "customer_name",label: "Customer Name",options: {filter: true,sort: true}},
      {name: "due_balance",label: "Due Balance",options: {filter: true,sort: true}},
      {name: "address",label: "Address",options: {filter: true,sort: true}},
      {name: "contact_no",label: "Contact No",options: {filter: true,sort: true}},

      {name: "pay_date",label: "Payment Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[6]).format(dateFormat) }</p>) 
        }
      }
      
    },

      
      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }




    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Customer Reminder Entry </h2>
      <Grid container spacing={2}>
      
       


        <Grid item xs={12} sm={3}> 
              <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}

             

              options={accounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccount}
              onChange={(event,selectedObj)=>{
                selectedAccountSet(selectedObj)

                if(selectedObj == null ){
                    customer_name_set('')
                    address_set('')
                    contact_no_set('')
                    return false
                }

                customer_name_set(selectedObj.acc_name)
                address_set(selectedObj.address)
                contact_no_set(selectedObj.contact_no)

                getDueBalance()
              }}
              renderInput={(params) => (
                <TextField
                inputRef={acc_type_ref}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    acc_due_balance_ref.current.focus()
                  }
                }}
                  {...params}
                  label="Select Customer "
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
        <Grid item xs={12} sm={3}> 
        <TextField type="number"  autoComplete='off' className={classes.fullWidth} 
          inputRef={acc_due_balance_ref}
          onKeyDown={event => {
            if (event.key === "Enter") {
              acc_pay_date_ref.current.focus()
            }
          }}
        label="Due Balance" name="due_balance" value={due_balance} variant="outlined" size="small" onChange={(e)=>due_balance_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3} style={{marginTop:'-20px'}}> 
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
       
        <KeyboardDatePicker
          margin="normal"
          label="Payment Date"
          format="MM/dd/yyyy"
          inputRef={acc_pay_date_ref}

          onKeyDown={event => {
            if (event.key === "Enter") {
              if(selectedAccount != null & (selectedAccount.acc_type_id=='creditor' || selectedAccount.acc_type_id=='debitor')){
                institution_ref.current.focus()
              }else if(selectedAccount != null & (selectedAccount.acc_type_id=='bank_account')){
                bank_acc_name_ref.current.focus()
              }else{
                saveFormAction()
              }
             
            }
          }}
         
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        
    
    </MuiPickersUtilsProvider>
        </Grid>

       
           <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
         inputRef={institution_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            address_ref.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Customer  Name" name="customer_name" value={customer_name} variant="outlined" size="small" onChange={(e)=>customer_name_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
         inputRef={address_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            contact_ref.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Address" name="address" value={address} variant="outlined" size="small" onChange={(e)=>address_set(e.target.value)} />
        </Grid>

        <Grid item xs={12} sm={3}> 
        <TextField  autoComplete='off' 
         inputRef={contact_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            location_ref.current.focus()
           }
         }}
          className={classes.fullWidth} 
        label="Contact No" name="contact_no" value={contact_no} variant="outlined" size="small" onChange={(e)=>contact_no_set(e.target.value)} />
        </Grid>

      



       
          




  
  </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Customer Reminder List"}
      data={reminders}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
      position:'relative'  
},
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);