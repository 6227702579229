import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Form, Radio } from "semantic-ui-react";
import { useHistory } from "react-router-dom";


import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime, dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
    const history = useHistory();



      // Initial values Set - Start
      let [sale_r_id,sale_r_id_set] = useState(0)
      let [sale_ind_id,sale_ind_id_set] = useState(null);
      let [action,action_set] = useState('create');
      let [sale_r_voucher_no,sale_r_voucher_no_set] = useState('');
      let [is_warehouse,is_warehouse_set] = useState('')
      let [currency,currency_set] = useState('')
      let [is_cal_type,is_cal_type_set] = useState('individual');
      let [is_serial,is_serial_set] = useState('no');
      let [availableSerials,availableSerialsSet] = useState([]);
  
      const [serialModal,serialModalSet] = useState(false)
      const [serial_number,serial_number_set] = useState('')
  
      let [customers,customersSet] = useState([]);
      let [salesReturnAccs,salesReturnAccsSet] = useState([]);
      let [fromAccs,fromAccsSet] = useState([]);
      let [serials,serialsSet] = useState([]);
      let [items,itemsSet] = useState([]);
      let [itemCart,itemCartSet] = useState([])
      let [payCart,payCartSet] = useState([])
      let [warehouses,warehouses_set] = useState([])
      let [transportAccs,transportAccsSet] = useState([])
  
      
      let [discountAccs,discountAccsSet] = useState([])
      let [taxAcc,taxAccSet] = useState([])
      let [loadingSave,loadingSaveSet] = useState(false)
  
      
  
      let [selectedWarehouse,selectedWarehouseSet] = useState(null);
      let [selectedItem,selectedItemSet] = useState(null);
      let [selectedCustomer,selectedCustomerSet] = useState(null);
      let [selectedSalesReturnAcc,selectedSalesReturnAccSet] = useState(null);
      let [selectedFromAcc,selectedFromAccSet] = useState(null);
  
  
  
      let [selectedDiscountAcc,selectedDiscountAccSet] = useState(null);
      let [selectedTaxAcc,selectedTaxAccSet] = useState(null);
      let [selectedTransportAcc,selectedTransportAccSet] = useState(null);
      const [created_date, created_date_set] = useState(currentDateTime);
  
      
  
      let [orderId,orderIdSet] = useState(0);


      let [item_qty,item_qty_set] = useState('');
      let [item_rate,item_rate_set] = useState('');
      let [item_discount_per,item_discount_per_set] = useState(0);
      let [item_discount,item_discount_set] = useState(0);
      let [item_tax_per,item_tax_per_set] = useState(0);
      let [item_tax,item_tax_set] = useState(0);
      let [item_total,item_total_set] = useState(0);
  
      let [retail_qty,retail_qty_set] = useState(0);
      let [sale_r_qty,sale_r_qty_set] = useState(0);
      let [sale_r_rate,sale_r_rate_set] = useState(0);
      let [conversion,conversion_set] = useState(0)
  
  
      let [total_discount,total_discount_set] = useState(0);
      let [total_discount_per,total_discount_per_set] = useState(0);
      let [total_tax,total_tax_set] = useState(0);
      let [total_tax_per,total_tax_per_set] = useState(0);
  
  
  
      let [sub_total,sub_total_set] = useState(0);
      let [total_transport_cost,total_transport_cost_set] = useState(0);
      let [total_amount,total_amount_set] = useState(0);
      let [paid_amount,paid_amount_set] = useState(0);
      let [due_amount,due_amount_set] = useState(0);

      

      let [tran_amount,tran_amount_set] = useState('');
  
      let [narration,narration_set] = useState('');
  
  
      const [item_name, item_name_set] = useState("");
      const [customer_name, customer_name_set] = useState("");
      const [acc_name, acc_name_set] = useState("General Customer");
      const [contact_no, contact_no_set] = useState("");
      const [institution_name, institution_name_set] = useState("");
      const [address, address_set] = useState("");

      const [from_acc_name, from_acc_name_set] = useState("");
      const [payCartShow, payCartShowSet] = useState(false);
      

      // Init value End

 // API Requests - Start
  useEffect(()=>{
    sale_r_id_set(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0);
    currentRouteSet(pathSpliter(location.pathname,1));
   
    is_warehouse_set(authInfo.is_warehouse)
    currency_set(authInfo.currency)
    is_cal_type_set(authInfo.is_cal_type)
    if(authInfo.is_warehouse == 'yes'){
      getWarehouses()
    }
 
    getSalesReturnAccs()
    getDiscountAccs()
    getTransportAccs()
    getTaxAccs()
    getSalesInv()
    item_ref.current.focus()
    if(pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0 != 0){
      loadingSaveSet(true)
      getSalesRecordWithDetail();
    }
  },[]);

  useEffect(()=>{
    getItems()
  },[item_name])

  useEffect(()=>{
    getCustomers()
    },[customer_name])

    useEffect(()=>{
        getFromAccs()
        },[from_acc_name])

        let getFromAccs = async ()=>{
            await  axios.post(`${API_URL}/api/get-accounts-by-search`,{query:from_acc_name.trim(),multiType:['cash_in_hand','bank_account']},{headers:{'auth-token':authInfo.token}}).then(res=>{
                fromAccsSet(res.data) 
              })
            }

            let getSalesReturnAccs = async ()=>{
              await  axios.post(`${API_URL}/api/get-accounts-by-search`,{multiType:['sale_return']},{headers:{'auth-token':authInfo.token}}).then(res=>{
                  salesReturnAccsSet(res.data) 

                  if(res.data.length != 0){
                    selectedSalesReturnAccSet({acc_id : res.data[0].acc_id,acc_name : res.data[0].acc_name})
                  }
                })
              }


  let getSalesRecordWithDetail = async ()=>{
      
    await axios.post(`${API_URL}/api/get-sales-return-record-with-details`,{sale_r_id:pathSpliter(location.pathname,3) != undefined?parseFloat( pathSpliter(location.pathname,3)):0},{headers:{'auth-token':authInfo.token}}).then(res=>{
      let data = res.data[0];
      let dataDetails = res.data[0].details;
      let trans = [];

         trans = res.data[0].trans;
         sale_r_voucher_no_set(data.sale_r_voucher_no);
         orderIdSet(data.order_id)

      
      
         selectedSalesReturnAccSet({acc_id : data.sales_return_acc_id,acc_name : data.sales_return_acc_name})

      
       is_cal_type_set(data.is_cal_type);
       created_date_set(data.created_date);
       narration_set(data.narration);
       sub_total_set(data.sub_total);
       total_discount_per_set(data.total_discount_per);
       total_discount_set(data.total_discount_per);
       total_tax_per_set(data.total_tax_per);
       total_tax_set(data.total_tax);
       total_transport_cost_set(data.total_transport_cost);

       total_amount_set(data.total_amount);
       is_serial_set(data.is_serial)
       selectedCustomerSet({acc_id: data.acc_id,acc_name: data.acc_name,display_text: data.acc_name,institution_name: data.institution_name,
              contact_no: data.contact_no,address: data.address})
       acc_name_set(data.acc_name)
       institution_name_set(data.institution_name)
       contact_no_set(data.contact_no)
       address_set(data.address)
       selectedDiscountAccSet({acc_id:data.discount_acc_id,acc_name: data.discount_acc_name})
       selectedTaxAccSet({acc_id:data.tax_acc_id,acc_name: data.tax_acc_name})
       selectedTransportAccSet({acc_id:data.transport_acc_id,acc_name: data.transport_acc_name})
       

       // Detail Data to Product Cart - Start
      let itemCartData =  dataDetails.map((item)=>{ 
        let serials = []
        
        serials = item.serials.split(',');
        if(item.is_serial == 'yes' ){
          serials = serials.map((slNo)=>{
            return {serial_number : slNo}
          })
        }else{
          serials = []
        }

          let updateItem = {
            warehouse_id : item.warehouse_id,
            warehouse_name : item.warehouse_name,
            item_id : item.item_id,
            item_name : item.item_name,
            display_text : item.display_text,
            is_serial: item.is_serial,
            item_qty:item.item_qty,
            item_rate:item.item_rate,
            item_discount:item.item_discount,
            item_discount_per:item.item_discount_per,
            discount_acc_id:item.discount_acc_id,
            discount_acc_name:item.discount_acc_name,
            item_tax:item.item_tax,
            item_tax_per:item.item_tax_per,
            tax_acc_id:item.tax_acc_id,
            tax_acc_name:item.tax_acc_name,
            serials,
            item_total:item.item_total,
            retail_qty:item.retail_qty,
            sale_r_qty:item.sale_r_qty,
            sale_r_rate:item.sale_r_rate,
            qty_display: item.item_qty +' '+ item.unit_symbol + (item.conversion >1 ? ', '+ item.retail_qty +' ' +item.base_unit_name:''),
            unit_symbol: item.unit_symbol,
            base_unit_name: item.base_unit_name,
            unit_name: item.unit_name,
            conversion: item.conversion,
          }
          return updateItem;
        });
        itemCartSet(itemCartData);

        // Detail Data to Product Cart - End

        // Transaction Cart start
        payCartSet(trans)

        // tran cart end

        loadingSaveSet(false)
    });
  }

 

  useEffect(()=>{
    if(selectedItem != null && selectedItem.is_serial == 'yes'){
       axios.post(`${API_URL}/api/get-serials-outs`,{itemId:selectedItem.item_id,warehouseId:selectedWarehouse != null ? selectedWarehouse.warehouse_id: 0 },{headers:{'auth-token':authInfo.token}}).then((res)=>{
        availableSerialsSet(res.data)
      });
    }
  },[selectedItem,selectedWarehouse])



 



        let getSalesInv = async ()=>{
          await  axios.get(`${API_URL}/api/get-sales-return-voucher-no`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            sale_r_voucher_no_set(res.data)
      
          })
        }

      let getDiscountAccs = async ()=>{
      await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_income',acc_name:'Discount on purchase'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          discountAccsSet(res.data)
          selectedDiscountAccSet(res.data.length != 0 ? res.data[0]:null)

        })
      }

      let getTransportAccs = async ()=>{
        // await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_expense'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        //   transportAccsSet(res.data)
        //   selectedTransportAccSet(res.data.length != 0 ? res.data[0]:null)

        // })
      }




      let getTaxAccs = async ()=>{
      await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'dutie_&_tax'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          taxAccSet(res.data) 
          selectedTaxAccSet(res.data.length != 0 ? res.data[0]:null)
        })
      }

  const getCustomers = async() => {
     await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:customer_name.trim(),type:'debitor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
   
    
       customersSet(res.data)
    })
};

  const getWarehouses = async() => {
      await axios.post(`${API_URL}/api/get-warehouses`,{"select-type":"active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
         warehouses_set(res.data.message)
      })
  };

  const getItems = async() => {
      await axios.post(`${API_URL}/api/get-items-by-search`,{query:item_name.trim(),search:true},{headers:{'auth-token':authInfo.token}}).then(res=>{
         itemsSet(res.data)
      })
  };

   // API Requests - End

 


    useEffect(()=>{
      item_discount_per_set(0)
      item_discount_set(0)
      item_tax_per_set(0)
      item_tax_set(0)

      total_discount_set(0)
      total_discount_per_set(0)
      total_tax_per_set(0)
      total_tax_per_set(0)
      total_transport_cost_set(0)

    },[is_cal_type])

    // Initial values Set - Start


    // Input Refs - Start
    let warehouse_ref = useRef(null)
    let item_ref = useRef(null)
    let item_qty_ref = useRef(null)
    let item_rate_ref = useRef(null)
    let retail_qty_ref = useRef(null)

    
    let item_discount_ref = useRef(null)
    let item_discount_per_ref = useRef(null)
    let item_tax_ref = useRef(null)
    let item_tax_per_ref = useRef(null)

    let acc_name_ref = useRef(null)
    let institution_name_ref = useRef(null)
    let contact_no_ref = useRef(null)
    let address_ref = useRef(null)
    let created_date_ref = useRef(null)
    let total_transport_cost_ref = useRef(null)

    let total_discount_ref = useRef(null)
    let total_discount_per_ref = useRef(null)
    let total_tax_ref = useRef(null)
    let total_tax_per_ref = useRef(null)


    let from_acc_ref = useRef(null)
    let tran_amount_ref = useRef(null)

    let paid_amount_ref = useRef(null)
    let due_amount_ref = useRef(null)
    
    // Input Refs - End

    useEffect(()=>{
        if(paid_amount != '' && paid_amount != 0){
            payCartShowSet(true)
        }else{
            payCartShowSet(false)
        }
    },[paid_amount])

    // Input Calculations - Start
    useEffect(()=>{
            let total_qty = item_qty * conversion;
            total_qty += parseFloat(retail_qty)
            let perQtyRate = item_rate / conversion;

                sale_r_rate_set(perQtyRate);
                sale_r_qty_set(total_qty);
           
        let  total = parseFloat(total_qty * perQtyRate).toFixed(2);

        if(is_cal_type == 'individual'){
           // Calculate Discount
        let item_discount = (total*item_discount_per)/100;
        item_discount_set(parseFloat(item_discount).toFixed(2)) 

        total -= parseFloat(item_discount)

        // Calculate Tax
        let item_tax = (total*item_tax_per)/100;
        item_tax_set(parseFloat(item_tax).toFixed(2)) 

        total += parseFloat(item_tax) 
        }
        
        item_total_set(parseFloat(total).toFixed(2))

    },[selectedItem,item_qty,item_rate,retail_qty])


    useEffect(()=>{
            let total_qty = item_qty * conversion;
            total_qty += parseFloat(retail_qty)
            let perQtyRate = item_rate / conversion;

            sale_r_rate_set(perQtyRate);
            sale_r_qty_set(total_qty);
           
        let  total = parseFloat(total_qty * perQtyRate).toFixed(2);

        if(is_cal_type == 'individual'){
          // Calculate Discount
        let item_discount = (total*item_discount_per)/100;
          // For Per Qty Rate Discount amount less
          sale_r_rate_set(parseFloat(perQtyRate) - parseFloat(item_discount / total_qty));
          // End
          
        item_discount_set(parseFloat(item_discount).toFixed(2)) 
        total -= parseFloat(item_discount)

        // Calculate Tax
        let item_tax = (total*item_tax_per)/100;

        item_tax_set(parseFloat(item_tax).toFixed(2)) 
        total += parseFloat(item_tax)

        }

        item_total_set(parseFloat(total).toFixed(2));
        
        },[item_discount,item_tax,item_discount_per,item_tax_per])

  
    useEffect(()=>{
    
     let subTotal =  itemCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.item_total)
      },0);
      sub_total_set(parseFloat(subTotal).toFixed(2));


        // Calculate Discount
        let total_discount = (subTotal*total_discount_per)/100;
        total_discount_set(parseFloat(total_discount).toFixed(2)) 

        subTotal -= parseFloat(total_discount)

        // Calculate Tax
        let total_tax = (subTotal*total_tax_per)/100;
        total_tax_set(parseFloat(total_tax).toFixed(2)) 

        subTotal += parseFloat(total_tax)


        subTotal += parseFloat(total_transport_cost)

        total_amount_set(parseFloat(subTotal).toFixed(2))




        let checkIsSerial =   itemCart.findIndex((ele)=>{
          if(ele.is_serial=='yes'){ 
            return true 
          }else{
            return false
          }
          }); 
      
          if(checkIsSerial>-1){
            is_serial_set('yes')
          }else{
            is_serial_set('no')
          }


          if(is_cal_type == 'individual'){
            let itemDiscount =  itemCart.reduce((prev,curr)=>{
              return prev+parseFloat(curr.item_discount)
            },0);
    
            total_discount_set(parseFloat(itemDiscount).toFixed(2))
    
            let itemTax =  itemCart.reduce((prev,curr)=>{
              return prev+parseFloat(curr.item_tax)
            },0);
            total_tax_set(parseFloat(itemTax).toFixed(2))
          }

    },[itemCart]);


 


    useEffect(()=>{

    

        // Calculate Discount
        let total_discount = (sub_total*total_discount_per)/100;

        sub_total -= parseFloat(total_discount)

        // Calculate Tax
        let total_tax = (sub_total*total_tax_per)/100;

        sub_total += parseFloat(total_tax)

        sub_total += parseFloat(total_transport_cost)


        total_amount_set(parseFloat(sub_total).toFixed(2))
    },[total_tax,total_tax_per,total_discount,total_discount_per,total_transport_cost])



    // Input Calculations - End

    // Methods  -  start
  

       // Data Save Method
      
    const saveSalesReturn = async ()=>{

      let days = moment().diff(created_date, "days")


      if(days >= 3 && authInfo.role != 'super_admin'){
        swal({
          title:`Now can't be Entry !`,
          icon:'warning',
       })
       return false
      }


      
          if(itemCart.length == 0){
            swal({
              title:`Item cart is Empty !`,
              icon:'warning'
            })
            return false
          }else if(selectedSalesReturnAcc == null){
            swal({
              title:`Select Sales Return Account !`,
              icon:'warning'
            })
            return false
          }else if(selectedDiscountAcc == null){
            swal({
              title:`Discount Account is Required!`,
              icon:'warning'
            })
            return false
          }else if(selectedTaxAcc == null){
            swal({
              title:`Vat Account is Required!`,
              icon:'warning'
            })
            return false
          }else if(selectedCustomer == null){
            swal({
              title:`Select customer !`,
              icon:'warning'
            })
            return false
          }else{
           
            let salesOrderData = {
                sale_r_id,
                orderId: orderId==''?0:orderId,
                itemCart,
                payCart,
                customer :{
                  acc_id: selectedCustomer.acc_id,
                  acc_name: acc_name.trim(),
                  institution_name,
                  contact_no,
                  address,
                },
                masterData:{

                  sale_r_voucher_no,
                  is_serial,
                  is_cal_type,
                  created_date,
                  narration,
                  sub_total,
                  total_discount_per,
                  total_discount,
                  total_tax_per,
                  total_tax,
                  total_transport_cost,
                  total_amount,
              
                  
                  sales_return_acc_id :  selectedSalesReturnAcc.acc_id,
                  discount_acc_id : selectedDiscountAcc != null ? selectedDiscountAcc.acc_id : 0,
                  tax_acc_id : selectedTaxAcc != null ? selectedTaxAcc.acc_id : 0,
                  transport_acc_id : selectedTransportAcc != null ? selectedTransportAcc.acc_id : 0,

                   
                }
            }

            

        let url = `/api/create-sales-return`
        if(pathSpliter(location.pathname,3) != undefined){
            url = `/api/update-sales-return`
        }
          loadingSaveSet(true) 
         await axios.post(`${API_URL}${url}`,salesOrderData,{headers:{'auth-token':authInfo.token}}).then(res=>{
          loadingSaveSet(false)

          if(res.data.error){
            swal({
              title: `${res.data.message}`,
              icon:'warning'
            })
            return false;
          }
          if(!res.data.error){
            swal({title:`${res.data.message}. Do you want to view invoice?`,icon:'success',buttons:true}).then(confirm=>{
              if(confirm){
                history.push(`/sales/quick-sales-return-voucher/${res.data.sale_r_id}`)
               
              }else{
                window.location.reload()
              }
            })
          }

          

         })


          }

            
          
          
    }


      // Item cart methods - Start

  const addTOCart = ()=>{

    if(selectedItem.item_id == 0){
      swal({
        title:`Select Item`,
        icon:'warning'
      });
      item_ref.current.focus()
      return false
    }
    
    if(is_warehouse == 'yes' && selectedWarehouse == null){
      swal({
        title:`Select Warehouse`,
        icon:'warning'
      });
      warehouse_ref.current.focus()
      return false
    }
    //  if(item_qty < 1){
    //   swal({
    //     title:`Invalid Quantity !`,
    //     icon:'warning'
    //   });
    //   item_qty_ref.current.focus()
    //   return false
    // }




    if( selectedItem.is_serial == 'yes' && serials.length == 0  ){
      swal({title:'Serial  cart is Empty.',icon:'warning'});return false;
    }

    if( selectedItem.is_serial == 'yes' && serials.length !=  sale_r_qty  ){
      swal({title:'Serial  & Item quantity   should be same',icon:'warning'});return false;
    }


    let obj = {
      warehouse_id : selectedWarehouse != null ? selectedWarehouse.warehouse_id :0,
      warehouse_name : selectedWarehouse != null ? selectedWarehouse.warehouse_name :'',
      item_id : selectedItem.item_id,
      item_name : selectedItem.item_name,
      display_text : selectedItem.display_text,
      is_serial: selectedItem.is_serial,
      item_qty,
      item_rate,
      item_discount,
      item_discount_per,
      discount_acc_id : selectedDiscountAcc != null ? selectedDiscountAcc.acc_id : 0,
      discount_acc_name : selectedDiscountAcc != null ? selectedDiscountAcc.acc_name : '',
      item_tax,
      item_tax_per,
      tax_acc_id : selectedTaxAcc != null ? selectedTaxAcc.acc_id : 0,
      tax_acc_name : selectedTaxAcc != null ? selectedTaxAcc.acc_name : 0,
      serials,
      item_total,

      retail_qty,
      sale_r_qty,
      sale_r_rate,
      qty_display: item_qty +' '+ selectedItem.unit_symbol + (conversion >1 ? ', '+ retail_qty +' ' +selectedItem.base_unit_name:''),
      unit_symbol: selectedItem.unit_symbol,
      base_unit_name: selectedItem.base_unit_name,
      unit_name: selectedItem.unit_name,
      conversion: conversion,
   }

   

    let checkExists =   itemCart.findIndex((ele)=>{
        let warehouseId = selectedWarehouse == null ? 0 : selectedWarehouse.warehouse_id;
        if(ele.item_id==selectedItem.item_id && ele.warehouse_id == warehouseId){ 
          return true 
        }else{
          return false
        }
    }); 

    if(checkExists>-1 && sale_ind_id != null){
      let preCopy =  [...itemCart];
      preCopy[sale_ind_id] = obj
      itemCartSet(preCopy)
    }else{
      itemCartSet([...itemCart,obj])
    }


     selectedWarehouseSet(null)
     selectedItemSet(null)
     item_qty_set('')
     item_rate_set('')
     item_discount_set(0)
     item_discount_per_set(0)

     sale_r_qty_set(0)
     sale_r_rate_set(0)
     retail_qty_set(0)

     item_tax_set(0)
     item_tax_per_set(0)

     sale_ind_id_set(null)

     serialsSet([])
     item_name_set('')


      item_ref.current.focus()

    }


    const editItemCart = (row,sl)=>{
       sale_ind_id_set(sl)
       selectedWarehouseSet({warehouse_id:row.warehouse_id,warehouse_name:row.warehouse_name});
       selectedItemSet({item_id:row.item_id,item_name:row.item_name,display_text:row.display_text,is_serial:row.is_serial,
        unit_symbol:row.unit_symbol,base_unit_name:row.base_unit_name,conversion:row.conversion,base_unit_name:row.base_unit_name,unit_name:row.unit_name,
        discount_per: row.discount_per,tax_per: row.tax_per});

       selectedTaxAccSet({acc_id:row.tax_acc_id,acc_name:row.tax_acc_name})
       selectedDiscountAccSet({acc_id:row.discount_acc_id,acc_name:row.discount_acc_name})
       serialsSet(row.serials)
       item_qty_set(row.item_qty)
       item_rate_set(row.item_rate)
       item_discount_set(row.item_discount)
       item_discount_per_set(row.item_discount_per)
       item_tax_set(row.item_tax)
       item_tax_per_set(row.item_tax_per)
       retail_qty_set(row.retail_qty)
       sale_r_qty_set(row.sale_r_qty)
       sale_r_rate_set(row.sale_r_rate)
       conversion_set(row.conversion)

    }


    const itemCartItemRemove = (sl)=>{
      let preCopy =  [...itemCart];
      preCopy.splice(sl, 1);
      itemCartSet(preCopy)
    }
  
      // Item cart methods - End

      // Item serial - methods
      const serialAdd = async ()=>{
        if(serial_number.trim() == ''){
          swal({
            title:`Serial Number is Required.`,
            icon:'warning'
          })
        }else{
         let serialCartCheck =   serials.findIndex((item)=>{
            return item.serial_number == serial_number.trim()
          });
  
          
  
  
          if(serialCartCheck > -1){
            swal({
              title:`Serial Number is exists on this serial cart `,
              icon:'warning'
            })
            return false
          }
          
          let itemCartCheck =   itemCart.findIndex((item)=>item.serials.some(s=> s.serial_number == serial_number.trim()));
  
          if(itemCartCheck > -1){
            swal({
              title:`Serial Number is exists on Item cart `,
              icon:'warning'
            })
          return false
          }else{
  
            let checkPreviousStock = false;
             await axios.post(`${API_URL}/api/check-serial-number`,{serial_number:serial_number.trim()},{headers:{'auth-token':authInfo.token}}).then((res)=>{
                 if(res.data.error){
                  checkPreviousStock = true
                 }
            });
  
            if(checkPreviousStock){
              swal({
                title:`Serial Number  exists on previous sales or order`,
                icon:'warning'
              });
              return false;
            }else{
              serialsSet([...serials,{serial_number:serial_number.trim()}])
              serial_number_set('');
            }
            
  
          }
          
        }
    }


    const serialRemove = (ind)=>{
      let hardCopy =  [...serials];
      hardCopy.splice(ind, 1);
      serialsSet(hardCopy)
    }





 useEffect(()=>{
  if(selectedItem != null){
  axios.post(`${API_URL}/api/get-stock`,{itemId:selectedItem.item_id,warehouseId: selectedWarehouse != null ? selectedWarehouse.warehouse_id : 0},{headers:{'auth-token':authInfo.token}}).then((res)=>{
   

    itemInfoSet({
      current_qty : res.data[0].current_qty,
      rate : res.data[0].average_rate,
      display_qty : res.data[0].display_qty,
    })
    
  });
  }
},[selectedItem,selectedWarehouse])








   let [itemInfo,itemInfoSet] = useState({
      current_qty : 0,
      display_qty : '',
      rate : 0,
    })




    
      // Item serial methods - end
    
    // Methods  -  End

    // Add Pay To cart
    const addPay = ()=>{
        if(selectedCustomer == null){
            swal({
                title:`Select Customer`,
                icon:'warning'
            })
            return false
        }else if(selectedFromAcc == null){
            swal({
                title:`Select To Account`,
                icon:'warning'
            })
            return false
        }else if(tran_amount == '' || tran_amount ==0){
          swal({
              title:`Transaction Amount is Required.`,
              icon:'warning'
          })
          return false
        }else{
            let pay = {
                from_acc_id : selectedFromAcc.acc_id,
                from_acc_name : selectedFromAcc.acc_name,
                tran_amount : tran_amount == ''?0:tran_amount,
            }
            payCartSet([...payCart,pay])
            selectedFromAccSet(null)
            tran_amount_set('')
        }
        

    }
    // 

    const removePayFromCart = (ind)=>{
        let hardCopy =  [...payCart];
        hardCopy.splice(ind, 1);
        payCartSet(hardCopy)
    }

    useEffect(()=>{
        due_amount_set(total_amount - paid_amount)
    },[total_amount])

  

 

     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Sales  Return  {pathSpliter(location.pathname,3) == undefined ? 'Entry' :"Update"}  </h2>
      <Grid container spacing={2}>
      <Grid  xs={12} sm={3}>
      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',padding:'1px',color:'#222',marginBottom: '8px'}}>Item cart / Product cart Information</p>

      {
     selectedItem != null  ? <>
       {
          itemInfo.current_qty > 0 ?(<p className="stock-availity">Available  : {itemInfo.display_qty}</p>)
          :(<p className="stock-unavaility">Unavailable  : {itemInfo.display_qty}</p>) 
       }
     </>:''
    }


      {
        is_warehouse == 'yes'?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>


    
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={warehouses} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.warehouse_name}
              value={selectedWarehouse}
              onChange={(event,selectedObj)=>{
                selectedWarehouseSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={warehouse_ref}
                  onKeyDown={(e)=>{
                    if(e.key==='Enter'){
                      item_ref.current.focus()
                    }
                  }}
                  {...params}
                  label="Select Warehouse"
                  variant="outlined"
                
                />
              )}
          />
      </Grid>
        
        </>):''
      }
      
      {/* <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold'}}>Select a Item (By Search)</p> */}
      <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      


      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          style={{ width: '100%' }}
          options={items} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.display_text}
          value={selectedItem}
          onChange={(event,selectedObj)=>{
            selectedItemSet(selectedObj)
            if(selectedObj != null){
              item_discount_per_set(selectedObj.discount_per)
              item_tax_per_set(selectedObj.tax_per)
              conversion_set(selectedObj.conversion)

              item_rate_set(selectedObj.item_rate)
            }
            
            item_qty_set('')
            retail_qty_set(0)
          }}
          renderInput={(params) => (
            <TextField
              inputRef={item_ref}
              onKeyDown={(e)=>{
                if(e.key==='Enter'){
                  item_qty_ref.current.focus()
                }
              }}
              onChange={(e)=>item_name_set(e.target.value)}
              {...params}
              label="Search Item"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>



      <Grid container xs={12} sm={12} >

      {
selectedItem != null && selectedItem.is_serial == 'yes'?(<>

<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      
      

      <Autocomplete 
          autoHighlight={true}
          openOnFocus={true}
          multiple
          limitTags={10}
          id="multiple-limit-tags" 
  
          style={{ width: '100%' }}
          options={availableSerials} 
          size="small"
          classes={{
            option: classes.option,
          }}
          getOptionLabel={(option) => option.serial_number}
          value={serials}
          onChange={(event,selectedObj)=>{
            serialsSet(selectedObj)
         
          
          }}
          renderInput={(params) => (
            <TextField
            
              {...params}
              label="Select Serials"
              variant="outlined"
            
            />
          )}
          
      />
      </Grid>

</>):''
}
      </Grid>


      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',marginBottom:'5px'}}>{
           selectedItem != null &&  conversion > 1  ?  selectedItem.unit_name :''
          }</p>

      <Grid container xs={12} sm={12} >
      
          
      <Grid item xs={12} sm={5}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_qty_set(0):item_qty_set(e.target.value)}
            autoComplete='off' className={classes.fullWidth} 
          label={`QTY ${selectedItem != null &&  selectedItem.item_id!=0?'('+selectedItem.unit_symbol+')':''}`} name="item_qty" value={item_qty} 
          inputRef={item_qty_ref}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              if( conversion > 1){
                retail_qty_ref.current.focus()
              }else{
                item_rate_ref.current.focus()
              }
             
            }
          }}
          variant="outlined" size="small" onChange={(e)=> item_qty_set(e.target.value)} />
                
                         
            </Grid>

            <Grid item xs={1} sm={1} ></Grid>
{
  conversion > 1 ?(<>
            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
           
            autoComplete='off' className={classes.fullWidth} 
          label={` ${selectedItem != null ? selectedItem.base_unit_name:''}  `} name="retail_qty" value={retail_qty} 
          inputRef={retail_qty_ref}
          onBlur={(e)=>e.target.value=='' || e.target.value < 0?retail_qty_set(0):retail_qty_set(e.target.value)}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              item_rate_ref.current.focus()
            }
          }}
          variant="outlined" size="small" onChange={(e)=> retail_qty_set(e.target.value)} />
                
                         
            </Grid>
  </>):''

}
        


            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            inputRef={item_rate_ref}
            onBlur={(e)=>e.target.value=='' || e.target.value < 0?item_rate_set(0):item_rate_set(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
              //  if(is_cal_type == 'on_total'){
                addTOCart()
              //  }else{
              //   item_discount_ref.current.focus()
              //  }
              }
            }}
           autoComplete='off' className={classes.fullWidth} 
         label="Rate" name="item_rate" value={item_rate} 
         variant="outlined" size="small" onChange={(e)=>item_rate_set(e.target.value)} />
               
                         
            </Grid>

        
       </Grid>
   
     {
       is_cal_type == 'individual'?(<>
              <Grid  container xs={12} sm={12} >
        <Grid  item xs={12} sm={5} style={{marginBottom:'10px'}}>
            <TextField type="number"  style={{width:'100%'}}
                    onBlur={(e)=>{
                      if(e.target.value==''){
                        item_discount_set(0)
                        item_discount_per_set(0)
                      }
                    }}
                    inputRef={item_discount_ref}
                    onKeyDown={(e)=>{
                      if(e.key==='Enter'){
                        item_discount_per_ref.current.focus()
                      }
                    }}
                    autoComplete='off' className={classes.fullWidth} 
                    label={`Discount (${currency} )`} name="item_discount" value={item_discount} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let total = (((item_qty*item_rate)) + parseFloat(item_tax)  );

                      let discountPercent = ((100 * e.target.value)/total)
                      item_discount_per_set(parseFloat(discountPercent).toFixed(2))

                     item_discount_set(e.target.value)

                    
                            
                    
                        }} />
            </Grid>

            <Grid item xs={1} sm={1} ></Grid>


            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
           
            autoComplete='off' className={classes.fullWidth} 
            onBlur={(e)=>{
              if(e.target.value==''){
                item_discount_set(0)
                item_discount_per_set(0)
              }
            }}
            inputRef={item_discount_per_ref}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                item_tax_ref.current.focus()
              }
            }}
          label="Discount %" name="item_discount_per" value={item_discount_per} 
          variant="outlined" size="small" onChange={(e)=>{

            let total = (((item_qty*item_rate)) + parseFloat(item_tax)  );

            let discountAmount = (total*e.target.value)/100;
            item_discount_set(parseFloat(discountAmount).toFixed(2)) 
            item_discount_per_set(e.target.value)

              }} />
                                         
            </Grid>
        </Grid>
       
       
      


        <Grid item xs={12} sm={12} style={{marginBottom:'10px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={discountAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedDiscountAcc}
              
              onChange={(event,selectedObj)=>{
                selectedDiscountAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Discount Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>




        <Grid container xs={12} sm={12} >

        <Grid item xs={12} sm={5} style={{marginBottom:'10px'}}>
            <TextField type="number"  
            
                     autoComplete='off' className={classes.fullWidth} 
                     onBlur={(e)=>{
                      if(e.target.value==''){
                        item_tax_set(0)
                        item_tax_per_set(0)
                      }
                    }}
                    inputRef={item_tax_ref}
                    onKeyDown={(e)=>{
                      if(e.key==='Enter'){
                        item_tax_per_ref.current.focus()
                      }
                    }}
                    label={`Vat (${currency} )`} name="item_tax" value={item_tax} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let total = (((item_qty*item_rate)) - item_discount );

                      let texPercent = parseFloat((100 * e.target.value)/(total)).toFixed(2)
                      item_tax_per_set(parseFloat(texPercent).toFixed(2))

                     item_tax_set(e.target.value)
                    
                            
                    
                        }} />
            </Grid>


            <Grid item xs={1} sm={1} ></Grid>


            <Grid item xs={12} sm={6} style={{marginBottom:'10px'}}>
            <TextField type="number"  
                    autoComplete='off' className={classes.fullWidth} 
                    onBlur={(e)=>{
                      if(e.target.value==''){
                        item_tax_set(0)
                        item_tax_per_set(0)
                      }
                    }}
                    inputRef={item_tax_per_ref}
                    onKeyDown={(e)=>{
                      if(e.key==='Enter'){
                        addTOCart()
                      }
                    }}
                    label="Vat  %" name="item_tax_per" value={item_tax_per} 
                    variant="outlined" size="small" onChange={(e)=>{
                      
                      let total = (((item_qty*item_rate)) - item_discount );
                      
                      let texAmount = parseFloat(total * (e.target.value/100)).toFixed(2)
                      item_tax_set(parseFloat(texAmount).toFixed(2))

                      item_tax_per_set(e.target.value)
                    
                            
                    
                        }} />
            </Grid>

        
</Grid>


      


            <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={taxAcc} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedTaxAcc}
              
              onChange={(event,selectedObj)=>{
                selectedTaxAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Vat Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
       </>):''
     }

        
         
         

    

            <Grid item xs={12} sm={12} >
            <TextField type="number"  
           disabled
           autoComplete='off' className={classes.fullWidth} 
         label="Total" name="item_total" value={item_total} 
         variant="outlined" size="small" onChange={(e)=>item_total_set(e.target.value)} />
               
                         
            </Grid>

        <span>Press Enter Key to sales Cart </span>


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOCart()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Cart</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={9}>
      <p style={{fontSize:'10px',margin:'0px',paddin:'0px',textAlign:'left',fontWeight:'bold',
      padding:'1px',color:'#222',marginTop:'-8px',marginBottom: '8px'}}>Creditor/ Customer, Item/ Product Cart & Total Amounts  Information</p>


      <Grid  container xs={12} sm={12} style={{marginBottom:'10px'}}>
      
      <Grid  item xs={12} sm={3} style={{marginBottom:'10px'}}>

        <Autocomplete 
                   options={customers} 
                   autoHighlight
                   size="small"
                   classes={{
                     option: classes.option,
                   }}
                   getOptionLabel={(option) => option.display_text}
                   value={selectedCustomer}
                   onChange={(event,selectedObj)=>{
                    selectedCustomerSet(selectedObj)
                    if(selectedObj != null){
                      acc_name_set(selectedObj.acc_name)
                      institution_name_set(selectedObj.institution_name)
                      contact_no_set(selectedObj.contact_no)
                      address_set(selectedObj.address)
                    }
                  
                   }}
                   renderInput={(params) => (
                     <TextField
                      
                       onChange={(e)=>customer_name_set(e.target.value)}
                       {...params}
                       label="Search Customer"
                       variant="outlined"
                     
                     />
                   )}

      
  />
      </Grid>
      <Grid  item xs={12} sm={4} style={{marginTop:'-4px',padding:'3px'}}>
        <TextField  autoComplete='off' disabled={selectedCustomer != null && (selectedCustomer.acc_id == 'G' || selectedCustomer.acc_id == 'N') ?"":"true"} className={classes.fullWidth} 
        label="Customer Name" name="acc_name" value={acc_name} 
        inputRef={acc_name_ref}
        onFocus={()=>selectedCustomer != null && (selectedCustomer.acc_id == 'G' || selectedCustomer.acc_id == 'N') && (acc_name.trim() == 'General Customer' || acc_name.trim() == 'New Customer') ? acc_name_set(''):acc_name} 
        onBlur={()=> {
          if(selectedCustomer != null && (selectedCustomer.acc_id == 'G' && acc_name.trim() == '')){
            acc_name_set('General Customer')
          }
          if(selectedCustomer != null && (selectedCustomer.acc_id == 'N' && acc_name.trim() == '')){
            acc_name_set('New Customer')
          }
        }} 
        onKeyDown={(e)=>e.key == 'Enter'?institution_name_ref.current.focus():false}
        variant="outlined" size="small" onChange={(e)=>acc_name_set(e.target.value)} />
      </Grid>

      <Grid  item xs={12} sm={5} style={{marginTop:'-4px',padding:'3px'}}>
        <TextField  autoComplete='off'  className={classes.fullWidth}
        inputRef={institution_name_ref} 
        onKeyDown={(e)=>e.key == 'Enter'?contact_no_ref.current.focus():false}
         disabled={selectedCustomer != null && (selectedCustomer.acc_id == 'G' || selectedCustomer.acc_id == 'N') ?"":"true"}
        label="Institution Name" name="institution_name" value={institution_name}  
        variant="outlined" size="small" onChange={(e)=>institution_name_set(e.target.value)} />
      </Grid>


      <Grid  item xs={12} sm={3} style={{marginTop:'3px',padding:'3px'}}>
        <TextField  autoComplete='off'  className={classes.fullWidth} 
         disabled={selectedCustomer != null && (selectedCustomer.acc_id == 'G' || selectedCustomer.acc_id == 'N') ?"":"true"}
         inputRef={contact_no_ref} 
         onKeyDown={(e)=>e.key == 'Enter'?address_ref.current.focus():false}
        label="contact No" name="contact_no" value={contact_no} 
        variant="outlined" size="small" onChange={(e)=>contact_no_set(e.target.value)} />
      </Grid>

      <Grid  item xs={12} sm={4}  style={{marginTop:'3px',padding:'3px'}}>
        <TextField  autoComplete='off'  className={classes.fullWidth} 
         inputRef={address_ref} 
         onKeyDown={(e)=>e.key == 'Enter'?created_date_ref.current.focus():false}
         disabled={selectedCustomer != null && (selectedCustomer.acc_id == 'G' || selectedCustomer.acc_id == 'N') ?"":"true"}
        label="Customer address" name="address" value={address} 
        variant="outlined" size="small" onChange={(e)=>address_set(e.target.value)} />
      </Grid>


        <Grid item xs={12} sm={2} style={{marginTop:'3px',padding:'3px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
      
        label="Voucher No" name="sale_r_voucher_no" value={sale_r_voucher_no} disabled
        variant="outlined" size="small" onChange={(e)=>sale_r_voucher_no_set(e.target.value)} />
        </Grid>

       

        <Grid item xs={12} sm={2} style={{float:'right',marginLeft:'5px'}}> 
       
<LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Entry Date" 
          inputFormat={dateTimeFormat}
          value={created_date}
          onChange={(e)=>created_date_set(e)}
          renderInput={(params) => <TextField {...params} />}
        />
        
      </Stack>
    </LocalizationProvider>

        </Grid>
       


  
  </Grid>
 



       



       
     

        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'15%'}}>Item Name</TableCell>
              {
                is_serial=='yes'?(<>
              <TableCell align="left"   style={{width:'14%'}}>Serials</TableCell>

                </>):''
              }
              
              {
                is_warehouse == 'yes'?(<>
                  <TableCell align="left"   style={{width:'10%'}}>Warehouse</TableCell>
                </>):''
              }
              <TableCell align="right"  style={{width:'10%'}}>QTY</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Rate</TableCell>
              {
                is_cal_type == 'individual'?(<>
                     <TableCell align="right"  style={{width:'10%'}}>Discount %</TableCell>
                     <TableCell align="right"  style={{width:'10%'}}>Vat %</TableCell>
                </>):''
              }
             
              <TableCell align="right"  style={{width:'10%'}}>Total</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                itemCart.map((item,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == sale_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{item.item_name}</TableCell>

                  {
                    is_serial == 'yes'?(<>
                     <TableCell align="left">
                      {
                        item.is_serial == 'yes'?(<>
                            {
                  
                            <>
                            ({item.serials.map((serial)=>(
                          <> <span>{serial.serial_number}</span>, </> 
                          ))})
                          </>
                            
                              }
                          
                        </>):''
                      }
                  </TableCell>
                    
                    </>):''
                  }
                 {
                   is_warehouse =='yes'?(<>
                          <TableCell align="left">{item.warehouse_name}</TableCell>
                   </>):''
                 }
                  <TableCell align="right">{item.qty_display}</TableCell>
                  <TableCell align="right">{format(parseFloat(item.item_rate).toFixed(2))}</TableCell>
                  {
                    is_cal_type == 'individual' ? (<>
                         <TableCell align="right">{item.item_discount_per}</TableCell>
                          <TableCell align="right">{item.item_tax_per}</TableCell>
                    </>):''
                  }
                
                  <TableCell align="right">{format(parseFloat(item.item_total).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editItemCart(item,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{itemCartItemRemove(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                itemCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={is_serial == 'yes'? (is_warehouse =='yes' ? 4 : 4-1) :(is_warehouse =='yes' ? 3 : 3-1)} style={{fontWeight:'bold'}}>Total  : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}></TableCell>
                    <TableCell align="right" colSpan={is_cal_type == 'individual'?3:1} style={{fontWeight:'bold'}}></TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(itemCart,(item)=>parseFloat(item.item_total))).toFixed(2))}</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>

    <Grid container xs={12} sm={12} style={{marginTop:'20px'}} >
             <Grid container  xs={12} sm={6}> 
           
{/* 
           <span style={{display:`${payCartShow?'block':'none'}`}}>
               
               <p style={{marginTop:'0px',marginBottom:'-20px',padding:'0px',fontWeight:'bold',marginBottom: '5px',textAlign:'left'}}>Payment From  Accounts</p>
         <Grid Grid container  xs={12} sm={12}>

         <Grid item xs={12} sm={6} style={{marginBottom:'10px'}}>

<Autocomplete
      autoHighlight={true}
      openOnFocus={true}
      style={{ width: '100%' }}
      options={fromAccs} 
      size="small"
      classes={{
        option: classes.option,
      }}
      getOptionLabel={(option) => option.acc_name}
      value={selectedFromAcc}
      
      onChange={(event,selectedObj)=>{
        selectedFromAccSet(selectedObj)
      }}
      renderInput={(params) => (
        <TextField
          inputRef={from_acc_ref}
          onKeyDown={(e)=>e.key == 'Enter'?tran_amount_ref.current.focus():true}
          onChange={(e)=>from_acc_name_set(e.target.value)}
          {...params}
          label="From Account"
          variant="outlined"
        />
      )}
  />

      </Grid>
      
        <Grid item  xs={12} sm={1}></Grid>
      <Grid item  xs={12} sm={3}>
        <TextField  
            inputRef={tran_amount_ref}
            onKeyDown={(e)=>e.key == 'Enter'?addPay():true}
            type="number"  autoComplete='off'  className={classes.fullWidth} 
            label=" Amount" name="tran_amount" value={tran_amount} 
            variant="outlined" size="small" onChange={(e)=>tran_amount_set(e.target.value)}  />
      </Grid>

      <Grid item  xs={12} sm={2}>
          <button style={{ fontSize: '14px',padding: '5px',background: '#eeffec',cursor: 'pointer',lineHeight: '1.9'}} onClick={()=>addPay()}>Add </button>
      </Grid>

         </Grid> */}


         {/* <Grid container xs={12} sm={12}>
                <TableContainer>
                   <Table>
                   <TableHead>
                        <TableCell align="left" width="3%">SL</TableCell>
                        <TableCell align="left" width="50%">From Account</TableCell>
                        <TableCell align="right" width="27%"> Amount</TableCell>
                        <TableCell align="right" width="20%">Action</TableCell>
                    </TableHead>
                   <TableBody>
                  
                       {
                           payCart.map((pay,ind)=>(<>
                            <TableRow>
                                <TableCell align="left">{parseFloat(ind)+1}</TableCell>
                                <TableCell align="left">{pay.from_acc_name}</TableCell>
                                <TableCell align="right">{format(parseFloat(pay.tran_amount).toFixed(2))}</TableCell>
                                <TableCell align="right" ><span style={{cursor:'pointer',fontWeight:'bold',color:'#c76500'}} onClick={()=>removePayFromCart(ind)}>Remove</span></TableCell>
                                </TableRow>
                           </>))
                       }                      
                    <TableRow>
                        <TableCell colSpan={2} align="right" style={{fontWeight:'bold'}}>Total Paid : </TableCell>
                        <TableCell align="right" style={{fontWeight:'bold'}}>{format(parseFloat(_.sumBy(payCart,(pay)=>Number(pay.tran_amount))).toFixed(2))}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                   </TableBody>
                   </Table>
                </TableContainer>

         </Grid> */}

               
               {/* </span> */}

   

         {/*  */}


             
        <Grid container xs={12} sm={12}>

        <Grid item xs={12} sm={12}>
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell><TextareaAutosize 
            
              
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',width: '100%'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>

    </Grid>

        </Grid>






              

            </Grid>
            <Grid item  xs={12} sm={1}></Grid>
            <Grid  container  xs={12} sm={5} >
               <Grid item  xs={12} sm={6} style={{paddingBottom: '20px'}}>
               <Form.Group inline>
    <label style={{color: '#222'}}> Discount and Vat  Method</label>
    <Form.Radio style={{color: '#222'}} label="On Total" checked={is_cal_type === 'on_total'}
            checked={is_cal_type === "on_total"}  value="on_total" onClick={() => { 
              itemCartSet([]) 
            is_cal_type_set('on_total')}} />
    <Form.Radio style={{color: '#222'}} label="Individual Item" checked={is_cal_type === 'individual'} value="individual" onClick={() => {
      itemCartSet([])
      is_cal_type_set('individual')}} />
    </Form.Group>


               </Grid>
               <Grid item  xs={12} sm={1}></Grid>
               <Grid item  xs={12} sm={5} style={{marginBottom:'10px'}}>
               <TextField  type="number" disabled autoComplete='off' disabled className={classes.fullWidth} 
                label={`Sub Total (${currency})`} name="sub_total" value={sub_total} 
                variant="outlined" size="small" onChange={(e)=>sub_total_set(e.target.value)}   />
               </Grid>


               
        <Grid item xs={12} sm={12} style={{marginBottom:'10px'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={salesReturnAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedSalesReturnAcc}
              
              onChange={(event,selectedObj)=>{
                selectedSalesReturnAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Sales Return Acc"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>

                 {
                   is_cal_type == 'on_total'?(<>
                           <Grid  container xs={12} sm={12} style={{marginTop: '7px'}}>
                           <Grid  item xs={12} sm={5} style={{marginBottom:'10px'}}>
            <TextField type="number"  
            inputRef={total_discount_ref}  
            onKeyDown={(e)=>e.key == 'Enter'?total_discount_per_ref.current.focus():false}
                  onBlur={(e)=>{
                    if(e.target.value==''){
                      total_discount_per_set(0)
                      total_discount_set(0)
                    }
                  }}
                    autoComplete='off' className={classes.fullWidth} 
                    label={`Discount (${currency} )`} name="total_discount" value={total_discount} 
                    variant="outlined" size="small" onChange={(e)=>{
                      let total = ((sub_total) + parseFloat(total_tax) );

                      let discountPercent = ((100 * e.target.value)/total)
                      total_discount_per_set(parseFloat(discountPercent).toFixed(2))

                     total_discount_set(e.target.value)

                    
                            
                    
                        }} />
            </Grid>

            <Grid item xs={1} sm={1} ></Grid>


            <Grid item xs={12} sm={6}  style={{marginBottom:'10px'}}>
            <TextField type="number"  
            inputRef={total_discount_per_ref} 
            onKeyDown={(e)=>e.key == 'Enter'?total_tax_ref.current.focus():false}
            autoComplete='off' className={classes.fullWidth} 
          label="Discount %" name="total_discount_per" value={total_discount_per} 
          onBlur={(e)=>{
            if(e.target.value==''){
              total_discount_per_set(0)
              total_discount_set(0)
            }
          }}
          variant="outlined" size="small" onChange={(e)=>{

            let total = (sub_total) + parseFloat(total_tax)  ;

            let discountAmount = (total*e.target.value)/100;
            total_discount_set(parseFloat(discountAmount).toFixed(2)) 
            total_discount_per_set(e.target.value)

              }} />
                                         
            </Grid>
        </Grid>
       
       
      


        <Grid item xs={12} sm={12} style={{marginBottom:'10px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={discountAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedDiscountAcc}
              
              onChange={(event,selectedObj)=>{
                selectedDiscountAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Discount Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>




        <Grid container xs={12} sm={12} >

        <Grid item xs={12} sm={5} style={{marginBottom:'10px'}}>
            <TextField type="number"  
              inputRef={total_tax_ref} 
              onKeyDown={(e)=>e.key == 'Enter'?total_tax_per_ref.current.focus():false}
                      onBlur={(e)=>{
                        if(e.target.value==''){
                          total_tax_per_set(0)
                          total_tax_set(0)
                        }
                      }}
                     autoComplete='off' className={classes.fullWidth} 
                    label={`Vat (${currency} )`} name="total_tax" value={total_tax} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let total = (sub_total ) - total_discount ;

                      let texPercent = parseFloat((100 * e.target.value)/(total)).toFixed(2)
                      total_tax_per_set(texPercent)

                      total_tax_set(e.target.value)
                    
                            
                    
                        }} />
            </Grid>


            <Grid item xs={1} sm={1} ></Grid>


            <Grid item xs={12} sm={6} style={{marginBottom:'10px'}} >
            <TextField type="number"  
             inputRef={total_tax_per_ref} 
             onKeyDown={(e)=>e.key == 'Enter'?total_transport_cost_ref.current.focus():false}
                    autoComplete='off' className={classes.fullWidth} 
                    label="Vat  %" name="total_tax_per" value={total_tax_per} 
                    onBlur={(e)=>{
                      if(e.target.value==''){
                        total_tax_per_set(0)
                        total_tax_set(0)
                      }
                    }}

                    variant="outlined" size="small" onChange={(e)=>{

                      let total = sub_total - total_discount  ;
                      
                      let texAmount = (total * (e.target.value/100))
                      total_tax_set(parseFloat(texAmount).toFixed(2))
                      total_tax_per_set(e.target.value)
                    
                            
                    
                        }} />
            </Grid>

        
</Grid>


      


            <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={taxAcc} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedTaxAcc}
              
              onChange={(event,selectedObj)=>{
                selectedTaxAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Vat Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
                   </>):''
                 }
     

        <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={transportAccs} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedTransportAcc}
              
              onChange={(event,selectedObj)=>{
                selectedTransportAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
            
                  {...params}
                  label="Choose a Transport Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>




        <Grid item  xs={12} sm={5} style={{marginBottom:'10px',display:'none'}}>
               <TextField  type="number"  autoComplete='off'  className={classes.fullWidth} 
               inputRef={total_transport_cost_ref}
               onKeyDown={(e)=>{
                 if(e.key == 'Enter'){
                    saveSalesReturn()
                 }
               }}
                label={`Transport Cost (${currency})`} name="total_transport_cost" required  value={total_transport_cost} 
                variant="outlined" size="small" onChange={(e)=>{
                  total_transport_cost_set(e.target.value)
                  }}
                  onBlur={(e)=>e.target.value==''?total_transport_cost_set(0):total_transport_cost_set(e.target.value)}
                  />
               </Grid>
               {/* <Grid item  xs={12} sm={1}></Grid> */}
               <Grid item  xs={12} sm={12} style={{marginBottom:'10px'}}>
               <TextField  type="number" disabled  autoComplete='off'  className={classes.fullWidth} 
                label={`Grand Total  (${currency})`} name="total_amount" value={total_amount} 
                variant="outlined" size="small" onChange={(e)=>total_amount_set(e.target.value)}/>
               </Grid>

{/* 
               <Grid container  xs={12} sm={12}>
                  
                   <Grid  item xs={12} sm={5} style={{marginBottom:'10px'}}>
                    <TextField type="number"  
                    inputRef={paid_amount_ref}  
                    onKeyDown={(e)=>e.key == 'Enter'?saveSalesReturn():false}
                    onBlur={(e)=>{
                    if(e.target.value==''){
                        paid_amount_set(0)
                    }
                  }}
                    autoComplete='off' className={classes.fullWidth} 
                    label="Paid Amount" name="paid_amount" value={paid_amount} 
                    variant="outlined" size="small" onChange={(e)=>{
                        paid_amount_set(e.target.value)
                        due_amount_set(total_amount - e.target.value)
                        
                        }} />
                   </Grid>
                   <Grid item  xs={12} sm={1}>
                   
                   </Grid>
                   <Grid item  xs={12} sm={6}>
                   <TextField type="number"  
                    disabled
                    autoComplete='off' className={classes.fullWidth} 
                    label="Due Amount" name="due_amount" value={due_amount} 
                    variant="outlined" size="small" onChange={(e)=>{
                        due_amount_set(e.target.value);
                        }} />
                   </Grid>
               </Grid> */}



               <Grid container  xs={12} sm={12}>

               <Grid item  xs={12} sm={6}>
                  <Button style={{marginTop: '25px',fontSize: '12px'}}
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={loadingSave}
                      className={classes.button}
                      startIcon={<SaveIcon />}
                      onClick={saveSalesReturn}
                    >
                      Save Sales Return
                    </Button>
               </Grid>

               <Grid item  xs={12} sm={1}>
               </Grid>
                        
               <Grid item  xs={12} sm={5}>
               <Button 
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  style={{color: 'white',
                  fontSize:'14px',
                    backgroundColor: '#115f5f',
                    marginTop: '30px'}}
                  disabled={loadingSave}
                  onClick={()=>{
                    window.location.reload();
                    return false;
                  }}
                >
                  Reset
                </Button>
               </Grid>
              
               </Grid>
              
              



            </Grid>
    </Grid>

  


        </Grid>



        </Grid>

      </Grid>

   
  

     </Paper>











       {/* Switch Branch  Modal */}
   <Modal
        open={serialModal}
        onClose={() => serialModalSet(false)}
        center
        style={{minWidth:'600px !important',minHeight:'500px'}}
 
      
      >
        <h2 style={{margin: 0,
    padding: 0,
    color: '#0f7e77'}}>Serial Number Add of <p style={{fontSize:'10px',margin: 0,
    padding: 0,color:'red'}}>{selectedItem != null ? selectedItem.item_name :''}</p></h2>
        <Grid item xs={12} sm={12} style={{display:'flex',marginTop:'5px',  display: 'flex',
   }} > 
      
        <Grid item  xs={12} sm={10}>
            <TextField label="Serail Number" variant="outlined"  size="small" 
             className={classes.fullWidth} value={serial_number} 
              onChange={(e)=>serial_number_set(e.target.value)}
              name="serial_number" 
             onKeyDown={(e)=>{
                  if(e.key == 'Enter'){
                    serialAdd()
                  }
             }}
              />
          </Grid> 

          <Grid item  xs={12} sm={2}>
              <Button style={{background:'green',fontSize:'20px',
               background: '#03d4be',
    fontSize: '15px'}}
    onClick={()=>serialAdd()}
    >Add</Button> 
          </Grid> 
      
          



         
        </Grid>


        <br/>
          <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">Serial</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

        
        
        {
            serials.map((serial,ind)=>(
              <TableRow>
              <TableCell align="left">{ind+parseFloat(1)}</TableCell>
              <TableCell align="left">{serial.serial_number}</TableCell>
              <TableCell align="right"  ><span style={{color:'red',fontSize:'18px',cursor:'pointer',margin:0,padding:0}} onClick={()=>serialRemove(ind)}>X</span></TableCell>
            </TableRow>
            ))
          }
        



        </TableBody>
      </Table>
    </TableContainer>



      </Modal>


     

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    fontSize:'10px',
    backgroundColor: '#115f5f'
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#240275',
      margin: '0px',
      padding: '0px',
      marginTop: '-19px',
      textAlign: 'left',
      marginLeft: '-8px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);